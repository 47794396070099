::selection {
    color: $primaryColor;
    background: $secondaryColor;
}

::-webkit-input-placeholder {
    color: $placeholderColor !important;
}

 :-moz-placeholder {
    color: $placeholderColor !important;
}

 ::-moz-placeholder {
    color: $placeholderColor !important;
}

:-ms-input-placeholder {
    color: $placeholderColor !important;
}


.animfadeIn{
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}
.animfadeIn
{
    opacity: 0;
}

a {
    color: $primaryColor;
    outline: none !important;
    &:hover {
        color: darken($primaryColor, 15%);
    }
}

h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
    font-weight: normal;
    font-family: $headingFont;
}

h1,
.h1 {
    font-size: $h1Size;
}

h2,
.h2 {
    font-size: $h2Size;
}

h3,
.h3 {
    font-size: $h3Size;
}

h4,
.h4 {
    font-size: $h4Size;
}

.wrapper {
    overflow: hidden; // overflow-y: auto;
    position: relative;
}

.btn {
   border-radius: 0;
   font-weight: 700;
   font-size: 15px;
   box-shadow: none !important;
 }

body {
    font-size: $bodyFontSize;
    line-height: $bodyLineHeight;
    overflow-x: hidden !important;
}