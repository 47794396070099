h1{
	font-weight: 400;
	color: $primaryColor;
	span{
		font-weight: 700;
	}
}
.headerSection{
	background-image: url(../images-greenbox/banner.jpg);
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;	
	width: 100vw;
	min-height: 620px;
	position: relative;
	overflow: hidden;
	.headerWrap{
		position: relative;
		z-index: 1;
	}

	.logoImg{
		padding-top: 10px;
		img{
			width: 175px;
		}
		svg{
			width: 175px;
		}
	}
	h1{
		display: block; font-size: 20px; letter-spacing: 0.5px; margin: 5px 0 0 5px;
		//text-shadow: 0px 0px 5px $white;
		text-shadow: -1px 0 rgba($white,0.4), 0 1px rgba($white,0.4), 1px 0 rgba($white,0.4), 0 -1px rgba($white,0.4);		
	}
	// &:before{
	// 	position: absolute;
	// 	content: '';
	// 	background:url(../images-greenbox/headerbg.png) repeat-x bottom left;
	// 	width: 100%;
	// 	height: 180px;
	// }
}
.loginBtn{
	font-weight: 700;
	display: inline-block;
	margin-right: 12px;
	text-shadow: -1px 0 rgba($white,0.4), 0 1px rgba($white,0.4), 1px 0 rgba($white,0.4), 0 -1px rgba($white,0.4);
}
.userName{
	font-weight: 700;
	color: $quaternaryColor;
	max-width: 300px;
	text-align: right;
	font-size: 18px;
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	letter-spacing: 1px;
	float: right;
	text-shadow: 1px 1px 2px black, 0 0 25px white, 0 0 5px black;
}
.videoWrap{
	width: 550px;
	height: 310px;
	overflow: hidden;
	position: relative;
	video,iframe{
		position: absolute;
		width: 100%;
		height: 100%;
	}
}
.videoContainer{
	margin: 98px 0;
}
.bodySection{
	.imageSliderWrapper{
		img{
			width: 100%;
		}
	}
	.aboutContentWrap{
		margin-right: calc(calc(100vw - 1140px) / 2);
		.aboutContent{
			padding:40px 0 0 10px;
			ul{
				margin: 10px 0 0;
				padding:0;
				font-size: 16px;
				list-style-type: none;
				li{
					background: url(../images-greenbox/bullet.png) no-repeat 0px 5px;
					padding: 0 0 15px 22px;
				}
			}
		}
	}	
	.priceWrap{
		margin-left: calc(calc(100vw - 1140px) / 2);
		padding: 35px 0 0;
		.price{
			font-size: 35px;
			color: $black;
			font-weight: 700;
			span{
				font-size: 40px;
				color: $primaryColor;
				font-family: arial;
			}
		}
		.cancellation{
			font-size: 13px;
			text-decoration: underline;
			color: #666;
			font-weight: 400;
			span{
				font-size: 15px;
				color: #666;
			}
			&:hover{
				color: $primaryColor;
				span{
					color: $primaryColor;					
				}
			}
		}
	}
}
.calendarSection{
	padding:50px;
	.calendarWrap{
		ul{
			list-style-type: none;	
		}
		.month {
		    padding: 20px 25px;
		    width: 100%;
		    background: $primaryColor;
		    text-align: center;
		    border-top-left-radius: 20px;
		    border-top-right-radius: 20px;
		}
		.month ul {
		    margin: 0;
		    padding: 0;
		}
		.month ul li {
		    color: white;
		    font-weight: 700;
		    font-size: 20px;
		    text-transform: uppercase;
		    letter-spacing: 3px;
		}
		.month .prev {
		    float: left;
		    padding-top: 10px;
		}
		.month .next {
		    float: right;
		    padding-top: 10px;
		}
		.weekdays {
		    margin:0;
		    padding:0px;
		    background-color: $secondaryColor;
		    text-transform: uppercase;
		    font-size: 13px;
		    border-left:1px solid $borderColor;
		    border-bottom:1px solid $borderColor;
		}
		.weekdays li {
		    display: inline-block;
		    float: left;
		    width: 14.28%;
		    color: $white;
		    padding:8px 15px;
		    font-weight: 500;
		    letter-spacing: 0.8px;
		    text-align: left;
		    border-right:1px solid $borderColor;
		    &:last-child{
		    	border:0px;
		    }
		}
		.days {
			margin:0;
		    padding: 0px;
		    background: #efefef;
		    border-left:1px solid $borderColor;
		    position: relative;
		}
		.days li {
		    display: inline-block;
		    float: left;
		    width: 14.28%;
		    text-align: center;
		    margin: 0px;
		    padding:10px 15px;
		    font-size:12px;
		    color: #777;
		    border-right:1px solid $borderColor;
		    border-bottom:1px solid $borderColor;
		    height: 90px;
		}

		.days li .active {
		    padding: 5px;
		    background: #1abc9c;
		    color: white !important
		}
		.days li a{
			text-decoration: none;
			&:hover{
				text-decoration: none;
			}
		}
		.calFooter {
		    padding: 10px 25px;
		    width: 100%;
		    background: $tertiaryColor;
		    text-align: right;
		    border-bottom-left-radius: 15px;
		    border-bottom-right-radius: 15px;
		    a{
		    	color: $white;
		    	&:hover{
		    		text-decoration: none;
		    		color:$quaternaryColor;
		    	}
		    }
		}
		.calFooter ul {
		    margin: 0;
		    padding: 0;
		}
		.calFooter ul li {
		    color: white;
		    font-weight: 700;
		    font-size: 16px;
		    text-transform: uppercase;
		    letter-spacing: 3px;
		    a{
		    	color: $white;
		    	&:hover{
		    		text-decoration: none;
		    		color:$quaternaryColor;
		    	}
		    }
		}
	}
}
.contactSection{
	background-color: $secondaryColor;
	padding:45px 0 45px;
	color: $white;
	.booknowBtn{
		position: absolute;
		right: -2px;
		bottom: 0px;
		margin-bottom: -17px;
	}
	p{
		color: rgba($white,0.8);
	}
	.fLeft{
		padding-right: 25px;
		text-align: right;		
		h3{
			font-size: 40px;
			color: $quaternaryColor;
			text-transform: uppercase;
			font-family: $bodyFont !important;
			font-weight: 700;
		}
	}
	.fRight{
		padding-left: 25px;
		.address{
			margin-top: 15px;
			position: relative;
			padding: 8px 0 0 70px;
			span{
				font-size: 22px;
				display: block;
				margin-bottom: 10px;
			}
			a{
				color: $white;
			}
			&:after{
				position:absolute;
				left: 0;
				top: 0;
				content: '\e9b6';
				font-family: icomoon;
				font-size: 50px;
			}
		}
	}
}
.footerSection{
	background-color: $primaryColor;
	padding:20px 0;
	color: $white;
	.copyright{
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 600;
		span{
			font-size: 12px;
			font-weight: 400;
			a{
				color: $white;
			}
		}
	}
	.social{
		font-size: 24px;
		color: $white;
		a{
			color: $white;
			display: inline-block;
			margin-left: 8px;
			&:hover{
				color: $white;
				text-decoration: none;
				opacity: 0.7;
			}
		}
	}
}
.booknowBtn{
	background-color: $btnbg;
	background-image: url(../images-greenbox/btn-bg.png);
	background-position: center center;
	padding: 12px 35px;
	border-radius: 25px;
	font-size: 16px;
	color: $white;
	text-transform: uppercase;
	font-weight: 700;
	transition: 0.3s all ease;
	border:0px;
	&:hover{
		color: $quaternaryColor;
		text-decoration: none;
		background-position: top center;
	}
}
.btn-primary{
	background-color: $btnbg;
	background-image: url(../images-greenbox/btn-bg.png);
	background-position: center center;
	border-radius: 25px;
	padding-left: 15px;
	padding-right: 15px;
	border:0px;
	color: $white;
	font-weight: 700;
	transition: 0.3s all ease;
	&:hover{
		background-color: rgba($btnbg,0.8);
		color: $quaternaryColor;
		text-decoration: none;
		background-position: top center;
	}
}
.btn-warning{
	background-color: $secondaryColor;
	border:0px;
	padding-left: 15px;
	padding-right: 15px;
	border-radius: 25px;
	color: $white;
	font-weight: 700;
	transition: 0.3s all ease;
	&:hover{
		background-color: rgba($secondaryColor,0.8);
		color: $tertiaryColor;
		text-decoration: none;
		background-position: top center;
	}
}
.date{
	text-align: left;
	h4{
		font-weight: 700;
		font-size: 28px;
		margin-bottom: 4px;
		color: $primaryColor;
	}
	.slots{
		font-weight: 400;
		color: $gray;
		span{
			display: block;
			font-weight: 700;
			color: $primaryColor;
		}
	}
}
.pastDate{
	color: $gray1;
	h4{
		color: $gray1;
	}
	.slots{
		display: none;
	}
}
.activeDate{
	background-color: $white;
}
#bookingform
{
	background-color: $primaryColor;
	color: $white;
	display: none;
	max-width: 750px;
	width: 100%;
	position: relative;
	.intro-content
	{
		padding: 0;
		.row{
			padding: 8px 20px;
		}
		.form{
		}
	}	
	label{
		margin:0px !important; 
	}
	.form-control{
		background-color: transparent;
		border-radius: 0;
		border:1px solid $white;
		width: 50px;
		color: $white;
		padding:6px 12px;
		text-align: center;
		margin-right: 12px;
	}
	.time-slots
	{
		min-height: 250px;
	}
}
.fancybox-content{
	padding: 10px;
	overflow: hidden;
}
.bg-form{
	background-color: $secondaryColor;
	padding: 10px 20px;
	font-size: 15px;
	.price{
		color: $white;
		font-size: 24px;
		font-weight: 600;
		span{
			color: #fff772;
			font-family: arial;
		}
	}
	.row{
		padding:0px !important;
	}
}
.slotTimingsRow{
	font-size: 14px;
	padding:4px 0 !important;
	input{
		margin-right: 10px;
	}
}

.styled-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  // Box.
  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 18px;
    height: 18px;
    background: none;
    border: 1px solid $white;
  }

  // Box hover
  &:hover + label:before {
    background: none;
  }
  
  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }


  // Box checked
  &:checked + label:before {
    background: none;
  }
  // Disabled state label.
  &:disabled + label {
    color: rgba($white,0.3);
    cursor: auto;
  }

  // slotFull box.
  &.slotFull + label:before {
    box-shadow: none;
    background: #fff772 !important;
    border: 1px solid #fff772 !important;
		color: $black !important;
  }

  // slotFull state label.
  &.slotFull + label {
    color: #fff772 !important;
    cursor: auto;
  }
  &.slotFull + label:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 8px;
    background: $black;
    width: 2px;
    height: 2px;
    box-shadow: 
      2px 0 0 $black,
      4px 0 0 $black,
      4px -2px 0 $black,
      4px -4px 0 $black,
      4px -6px 0 $black,
      4px -8px 0 $black;
    transform: rotate(45deg);
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: none;
    border: 1px solid rgba($white,0.2);
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 8px;
    background: $white;
    width: 2px;
    height: 2px;
    box-shadow: 
      2px 0 0 $white,
      4px 0 0 $white,
      4px -2px 0 $white,
      4px -4px 0 $white,
      4px -6px 0 $white,
      4px -8px 0 $white;
    transform: rotate(45deg);
  }
}
.waitlistTxt{
	a{
		font-size: 12px;
		color: #fff772;
		text-decoration: underline;
		font-weight: 600;
		display: inline-block;
		margin-left: 15px;
	}
}
.slotIndication{
	font-size: 13px;
	.Slotavailable{
		border:1px solid $white;
		width: 12px;
		height: 12px;
		display: inline-block;
		margin-right: 6px;
	}
	.Slotfull{
		border:1px solid #fff772;
		background-color: #fff772;
		width: 12px;
		height: 12px;
		display: inline-block;
		margin-right: 6px;
		color: $black !important;
	}
}
.bookingBtnWrap{
	padding:20px 20px 10px;
	min-height: 82px;
}

.popup{
	background-color: $primaryColor;
	color: $white;
	max-width: 750px;
	width: 100%;
	display: none;
}
.register
{
	position: relative;
	overflow: hidden;
	>div
	{
		z-index: 9;
    	height: 100%;
    	>div
    	{
    		overflow: hidden;
		    width: 80%;
		    display: block;
		    margin: 0 auto;
		    h4
		    {
		    	font-weight: 700;
		    	color: $white;
		    	font-size: 30px;
		    }
		    a{
		    	color: $quaternaryColor;
		    }		   
    	}
	}
	&:after
	{
		content: "";
		position: absolute;
		background-color: rgba($black, 0.50);
		height: 100%;
    	width: 100%;
    	left: 0;
	    right: 0;
	    top: 0;
	    border: 0;
	    z-index: 1;
	}
}
.register-lg
{
	border-top: 1px solid $secondaryColor;
	margin-top: 20px;
    padding-top: 20px;
    h5
    {
    	font-weight: 700;
    	color: $black;
    	font-size: 30px;
    }
    .biganchor
    {
    	color: $secondaryColor;
    	border-bottom: 2px solid $secondaryColor;
    	&:after, &:before
    	{
    		display: none;
    	}
    }
}


/*#forgot-form, #activation-form, .login-hide, .registerForm
{
	display: none;
}*/
#activation-form, .login-hide
{
	display: none;
}
.login-section{
	.form-group{
		padding-bottom: 10px;
	}
	padding:50px 30px;
	h4{
		margin-bottom: 15px;
		span{
			color: $quaternaryColor;
		}
	}
	button{
		cursor: pointer;
		&:focus
		{
			outline: 0;
		}
	}
}
.signup-section{
	.form-group{
		padding-bottom: 10px;
	}
	padding:50px 30px;
	h4{
		margin-bottom: 15px;
		span{
			color: $quaternaryColor;
		}
	}
	button{
		cursor: pointer;
	}
}
.form-control{
	background-color: transparent;
	border-radius: 0;
	border:1px solid $white;
	color: $white;
	padding:6px 12px;
	margin-right: 12px;
	&:focus{
		background-color: transparent;
		color: $quaternaryColor;
	}
}
.bottomLinks{
	margin-top: 18px;
	a{
		color: $quaternaryColor;
		text-decoration: underline;
	}
}
.register-btn{
	font-weight: 600;
	font-size: 28px;
	text-decoration: underline;
}
.registerForm{
	h4{
		span{
			a{
				color: $quaternaryColor;
				text-decoration: underline;
			}
		}
	}
}
.usermenu, .cartItems, .priceItems{
  background-color: $quaternaryColor;
  border-radius: 15px;
  width: 150px;
  position: absolute;
  z-index: 99999;
  top: 35px;
  right: 0;
  box-shadow:0px 2px 3px $secondaryColor;
  ul{
    padding:10px 0px;
    list-style-type: none;
    width: 100%;
    margin: 0;
    li{
      padding:0 !important;
      margin: 0 !important;
      border:0px !important;
      color: $black;
      width: 100%;
      line-height: normal !important;
      font-size: 14px;
      a{
        display: block;        
        color: $black;
        padding:6px 15px;
        &:hover{
          background-color: $secondaryColor;
          color: $white;
          text-decoration: none;
        }
      }
    }
  }
}
a.arrowNav{
	background-color: $secondaryColor;
	padding:12px 35px 12px 38px;
	border-radius: 25px;
	color: $white;
	text-decoration: none;
	font-size: 16px;
	span{
		font-size:20px !important;
		position: absolute;
		left: 13px;
	    top: 2px;
	}
	&:after{
	    content: '\e958';
	    font-family: icomoon;
	    position: absolute;
	    right: 10px;
	    top: 3px;
	    font-weight: normal;
	    font-size: 16px;
	}
	&:hover{		
	    text-decoration: none;
	    color: $quaternaryColor;
	}
}
a.active{
  color:$quaternaryColor;
  &:after{
    content: '\e95b';
  }
}
.mybookingsWrap{
	padding:40px 0;
	.bookingList{
		margin-bottom: 20px;
		&:last-child{
			margin-bottom: 0;
		}
	}
	.slots{	
		li{
			background-color: $primaryColor;
			padding:8px 15px;
			color: $white;
			float: left;
			border-radius: 15px;
			font-size: 14px;
			margin-right: 5px;
			margin-bottom: 5px;
			&.date{
				background-color: $white;
				padding:8px 15px 8px 0;
				color: $primaryColor;
				font-weight: 700;		
			}
		}
	}
}
.fancybox-slide--iframe .fancybox-content {
	width  : 750px !important;
	height : 300px !important;
	max-width  : 95%;
	max-height : 100%;
	margin: 0;
	background-color: #1d6838 !important;
	color: #ffffff;
}
.fancybox-slide--iframe .fancybox-content{
	background: #1d6838 !important;
	background-color: #1d6838 !important;
	color: #ffffff;
}
.swiper-button-next, .swiper-button-prev{
	top:60px;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev{
	right: 25px;
	background-image: url(../images-greenbox/right.svg);
}
.swiper-button-prev, .swiper-container-rtl .swiper-button-next{
	left: auto;
	right: 60px;  
	background-image: url(../images-greenbox/left.svg);
}
.inner-page{
	.headerSection{
		min-height: 320px;
		background-size: cover;
	}
	.videoContainer{
		display: none;
	}
}

/* Abhishek 2018/10/05 - Remove spinners from input-type number */
input[type='number'] {
    -moz-appearance:textfield;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

[ng\:cloak],[ng-cloak],[data-ng-cloak],[x-ng-cloak],.ng-cloak,.x-ng-cloak{display:none !important}
.no-animate, .no-animate-children * {
   -webkit-transition: none !important;
   transition: none !important;
}

/* Entry CSS angularjs */
.ng-enter {
   transition: opacity 0.4s ease-in;
   backface-visibility: hidden;
}
.ng-enter {
    opacity:0;
}
.ng-enter.ng-enter-active {
    opacity:1;
}

/* Leave CSS angularjs */
.ng-leave {
   display: none;
}
.ng-leave.ng-leave-active {
    display: none;
}
.ng-leave {
    display: none;
}

/* Move CSS angularjs */
.ng-move {
   transition: opacity 0.4s ease-in;
   backface-visibility: hidden;
}
.ng-move {
    opacity:0;
}
.ng-move.ng-move-active {
    opacity:1;
}

.ng-invalid.ng-touched, .ng-submitted .ng-invalid {
    border-color: #ff0000 !important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075) !important;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075) !important;
}

.ng-invalid.ng-touched:focus, .ng-submitted .ng-invalid:focus {
    border-color: #ff0000;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #ff0000;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 6px #ff0000;
}
.fullPageLoader {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: fixed;
	display: block;
	background-color: rgba(255,255,255,0.7);
	z-index: 99;
	text-align: center;
	background-image: url(../images-greenbox/load.gif);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100px 100px;
}
.fullBoxLoader {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	display: block;
	background-color: rgba(255,255,255,0.7);
	z-index: 99;
	text-align: center;
	background-image: url(../images-greenbox/load.gif);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60px 60px;
}

.calender-loader
{
	position: absolute;
    width: 100% !important;
    height: 100% !important;
    background: rgba(255,255,255,0.7);
    z-index: 9;
    display: flex !important;
    align-items: center;
    text-align: center !important;
    margin: auto !important;
    img
    {
    	margin: auto;
    	width: 60px;
    }
}

button
{
	&:focus
	{
		outline: 0;
	}
}
.help-block{
	position: absolute;
	font-size: 11px;
	color: $white;
	margin-top: 3px;
}

.pagination{
    list-style-type: none;
    float: left !important;
    li{
        margin-left: 3px;
        font-size: 12px;
        a{
        	color: $gray;
        	border:1px solid $white;
        	display: inline-block;
        	padding:2px 6px;
        	border-radius: 3px;
        	font-weight: 600;
        }

    	&.current{
    		a{
	        	border:1px solid $gray;
	        	color: $primaryColor
        	}
    	}
    }
}
.errorMessege{
	top: -8px;
	left: -15px;
	font-size: 13px;
	position: absolute;
	color: $white;
	background-color: #bb0000;
	width: calc(100% + 30px);
	height: 25px;
	line-height: 25px;
	text-align:center;
}.successMessege{
	top: -8px;
	left: -15px;
	font-size: 13px;
	position: absolute;
	color: $white;
	background-color: #129f45;
	width: calc(100% + 30px);
	height: 25px;
	line-height: 25px;
	text-align:center;
}.warningMessage{
	font-size: 11px;
	color: $tertiaryColor;
	display: block;
}
.styled-checkbox.slotChecked+label {
    color: #fff772 !important;
    cursor: auto;
}
.undo_msg_style{
	height: auto !important;
	line-height: 25px !important;
}
.set_msg{
	font-size: 13px;
	color: #3efe54;
	padding-left: 22px;
	position: relative;
	height:25px;
	line-height: 25px;
	&:before{
		content:'\e956';
		position: absolute;
		font-family: icomoon;
		font-size: 16px;
		color: #3efe54;
		left:0;
	}
}
.warning_msg{
	font-size: 13px;
	color: #e9e00d;
	padding-left: 22px;
	position: relative;
	height:25px;
	line-height: 25px;
	&:before{
		content:'\e92e';
		position: absolute;
		font-family: icomoon;
		font-size: 16px;
		color: #e9e00d;
		left:0;
	}
}
.error_msg{
	font-size: 13px;
	color: #e8a8a8;
	padding-left: 22px;
	position: relative;
	height:25px;
	line-height: 25px;
	&:before{
		content:'\ea2d';
		position: absolute;
		font-family: icomoon;
		font-size: 16px;
		color: #e8a8a8;
		left:0;
	}
}
.waitingDisabled{
	color: rgba($white,0.3) !important;
}
.ucBg{
	position: relative;
	background-image: url(../images-greenbox/ucBg.jpg) !important;
	&:after{
		content: '';
		position: absolute;
		background-color: rgba($white,0.8);
		width:100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	h2{
		font-size: 25px;
		font-weight: 500;
		color: $secondaryColor;
		margin-top: 30px;
		font-family: $bodyFont;
		span{
			display: block;
			font-size: 50px;
		}
	}
}
.submitBtnWrapper{
	min-height: 55px;
	line-height: 55px;
	.booknowBtn{
		line-height: normal;
	}
	.set_msg,.error_msg{
		height: 55px;
		line-height: 55px;
	}
}
#terms{
	p{
		font-size: 14px;
	}
}
.text-warning{
	color: #e39000 !important;
}
.lostnFound{
	margin-top: 30px;
	padding: 15px 20px;
	border:1px dashed $gray1;
	border-radius: 10px;
	h3{
		color: $primaryColor;
		font-weight: 600;
		font-size:17px;
	}
	p{
		font-size: 13px;
		color: #666666;
		margin-bottom: 0;
	}
	span{
		color: $black;
		font-weight: 700;
	}
}
.imageSize1{
    cursor: zoom-in;
    height: 100%;
    background-color: #ffffff;
    position: relative;
    display: block;
    position: relative;
    overflow: hidden;
    transition: all 1s;
    span{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        backface-visibility: hidden;
        transition: all 0.3s;
         img{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            display: block;
            max-width: 100%;
            max-height: 100%;
            backface-visibility: hidden;
            cursor:zoom-in;
         }
    }
}
.imageSize2{
    padding-bottom: 65%;
    background-color: transparent;
    display: block;
    position: relative;
    overflow: hidden;
    transition: all 1s;
    span{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        backface-visibility: hidden;
        transition: all 0.3s;
        background-size: cover;
        background-position: center center;
       img{
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
          display: block;
          max-width: 100%;
          max-height: 100%;
          backface-visibility: hidden;
          padding:0 !important;
       }         
    }
}

.plyr{
  position: absolute !important;
  height:100% !important;
  width: 100% !important;
  background:$black;
  .plyr__video-wrapper{
    height: 100%;
  }  
}
.plyr audio, .plyr video{
  width: 100% !important;
  height: 100% !important;
}
