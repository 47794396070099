@media(max-width: 1199px) {
	.aboutContentWrap{
		margin-right: 25px !important;
	}
	.priceWrap{
		margin-left: 25px !important;
	}
	.headerSection{
		min-height: 500px;
	}
    
}
@media(max-width: 991px) {
	.bodySection{
		/*overflow-x:hidden;*/
	}
	.headerSection{
		min-height: 450px;
	}
	.calendarSection{
		padding:30px;
	}
	.videoWrap{
		width: 450px;
		height: 270px;
		overflow: hidden;
		position: relative;
		video,iframe{
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}
}
@media(max-width: 767px) {
	.headerSection{
		min-height: 400px;
	}
	.videoContainer{
		margin: 35px 0;
	}
	.sm-hide{
		display: none;
	}
	.aboutContentWrap{
		margin-right: 25px !important;
		margin-left: 25px !important;
	}
	.priceWrap{
		margin-right: 25px !important;
		margin-left: 25px !important;
	}

	.calendarWrap{
		margin: 0 -8px;
	}
	.calendarSection{
		padding:30px 0px 20px;
		.slots{
			// display: none;
			font-size: 10px;
			font-weight: 700;
			text-align: center;
			span.dot{
				display: inline-block !important;
			}
			span.hideTxt,span.bookingTxt{
				display: none;
			}
		}
		.days li {
			padding: 4px !important;
			height: 50px !important;
			h4{
				font-size: 16px !important;
				text-align: center;
			}			
			&.calender-loader{
				height: 100% !important;
			}
		}
		.weekdays li{
			padding: 8px !important;
		}
		.calFooter ul li{
			font-size: 12px !important;
			letter-spacing: 1px  !important;
		}
	}
	.contactSection{
		padding-bottom: 30px !important;
	}
	.contactSection .fLeft{
		text-align: center;
		padding-bottom: 30px;
	}
	.contactSection .border-right{
		border:0px !important;
		text-align: center;
	}
	.contactSection .booknowBtn{
		position: relative;
		right: auto;
		bottom: auto;
		margin: 0px auto 25px;
		display: inline-block;
		border:0px;
	}
	#bookingform .intro-content .row{
		padding:7px 15px;
	}
	// .register>div>div h4{
	// 	font-size: 20px !important;
	// }
	// .loginForm .register-btn{
	// 	font-size: 20px;
	// }
	.login-section,.signup-section{
		padding: 20px;
	}	
	.fancybox-slide--iframe .fancybox-content {
      width  : 750px !important;
      height : 80vh !important;
      max-width  : 95%;
      max-height : 100%;
      margin: 0;
      background-color: #1d6838 !important;
      color: #ffffff;
	}
	html,body{
	  	overflow-x:hidden !important;
	  	overflow-y:auto !important;
	}
	.contactSection .fLeft, .contactSection .fRight{
		padding-right: 0px;
		padding-left: 0px;
		h3{
			font-size: 25px;
		}
	}
	.calendarSection .calendarWrap .calFooter{
		font-size: 13px;
	}
	.time-slots .col-sm-6.col-12{
		.row{
			padding:0px !important;
			.row{
				padding: 5px 10px !important;
			}
		}
	}
	.animfadeIn{
		opacity: 1 !important;
		visibility: visible !important;
	}
    
}
@media(max-width: 575px) {
	.videoContainer{
		margin:0px;
	}
	.videoWrap{
		width: 100% !important;
		height: auto !important;
		margin:0 auto;
		overflow: hidden;
		position: relative;
		video,iframe{
			position: relative;
			width: 100%;
			height: 100%;
		}
	}
	.plyr{
	  position: relative !important;
	  height:100% !important;
	  width: 100% !important;
	  background:$black;
	  .plyr__video-wrapper{
	    height: 100%;
	  }  
	}
	.plyr audio, .plyr video{
	  width: 100% !important;
	  height: 100% !important;
	}
	.userName{
		max-width: 130px;
		font-size: 13px;
		font-weight: normal;
	}	
	.headerSection{
		min-height: 220px;
	}
	.submitBtnWrapper{
		min-height: 40px;
		line-height: 40px;
		.booknowBtn{
			line-height: normal;
		}
		.set_msg,.error_msg{
			height: 40px;
			line-height: 40px;
		}
	}
	.mybookingsWrap .slots li{
		padding: 4px 12px;
		font-size: 12px;
	}	
	.bookingList h3{
		font-size: 20px;
	}
	.bookingList .card-header h4{
		font-weight: 700;
		color: $primaryColor;
	}
	.bookingList .card-footer .small{
		font-size: 12px !important;
	}
	.fancybox-content{
		padding: 0;
	}
	.mybookingsWrap{
		padding: 20px !important;
	}
	#confirm_booking_popup .mybookingsWrap{
		padding: 0 20px 10px !important;
	}
	#confirm_booking_popup h2{
		font-size: 22px;
		font-weight: 700;
		margin:0;
		padding: 15px !important;
	}
	.register>div>div h4{
		font-size: 20px !important;
	}
	.loginForm .register-btn{
		font-size: 20px;
	}
	.signup-section{
		h4{
			margin:0;
			padding-top: 0px;
			span{
				margin-bottom: 15px;
				display: block;
			}
		}
		.text-center{
			h4{
				font-size: 15px !important;
			}
		}
	}
	.booknowBtn{
		padding:8px 15px;
		font-size: 12px;
		border: 0px;
	}
	a.arrowNav{
		padding: 6px 28px 5px 28px;
		span{
			font-size: 18px !important;
		}
		font{
			display: none;
		}
	}
	.headerSection .logoImg img{
		width: 120px;
	}
	.headerSection .logoImg svg{
		width: 120px;
	}
	.headerSection h1{
		font-size: 11px;
		letter-spacing: 1.5px;
		text-shadow: 1px 1px 2px $white, 0 0 25px white, 0 0 5px white;
	}
	.address{
		margin-top: 15px;
		position: relative;
		font-size: 14px !important;
		padding: 68px 0 0 0px !important;
		text-align: center;
		span{
			font-size: 18px !important;
			font-weight: bold !important;
			display: block;
			margin-bottom: 10px;
		}
		a{
			color: $white;
		}
		&:after{
			position:absolute;
			left: 50% !important;
			margin-left: -25px;
			top: 0;
			content: '\e9b6';
			font-family: icomoon;
			font-size: 50px !important;
		}
	}
	.footerSection .copyright{
		font-size: 12px;
	}   
	#bookingform .intro-content .row{
		padding:7px 10px;
	}
	.bookingBtnWrap{
		padding:20px 15px 10px;
		.booknowBtn{
			padding:9px 20px !important;
			border:0px;
		}
	}
	.slotIndication{
		float: right;
		font-size: 11px;
		margin-bottom: 12px;
		.Slotavailable{
			border:1px solid $white;
			width: 8px;
			height: 8px;
			display: inline-block;
			margin-right: 6px;
		}
		.Slotfull{
			border:1px solid #fff772;
			background-color: #fff772;
			width: 8px;
			height: 8px;
			display: inline-block;
			margin-right: 6px;
		}
	}
	.bg-form .price{
		margin-bottom: 10px;
	}
	.register{
		position: relative;
		overflow: hidden;
		>div
		{
			z-index: 9;
	    	height: 100%;
	    	>div
	    	{
	    		overflow: hidden;
			    width: 100%;
			    display: block;
			    margin: 0 auto;
			    text-align: center;
			    padding:15px 0;
			    h4
			    {
			    	font-weight: 700;
			    	color: $white;
			    	font-size: 20px;
			    }
			    a{
			    	color: $quaternaryColor;
			    }		   
	    	}
		}
		&:after
		{
			content: "";
			position: absolute;
			background-color: rgba($black, 0.50);
			height: 100%;
	    	width: 100%;
	    	left: 0;
		    right: 0;
		    top: 0;
		    border: 0;
		    z-index: 1;
		}
	}
	#edit-profile, #change-password{
		.signup-section .form-group{
			padding-bottom: 0px !important;
		}
	}
}

@media(max-width: 345px) {
	.calendarSection{
		.slots{
			position: relative;
			margin: 0 -2px;
			// display: none;
			font-size: 9px;
			text-align: center;
		}
		.days li {
			padding: 4px !important;
			height: 56px !important;
		}
		.weekdays li{
			padding: 8px !important;
		}
		.calFooter ul li{
			font-size: 12px !important;
		}
	}	
}

@media(max-width: 490px) {
	.calendarSection{
		.slots{
			position: relative;
			font-size: 9px;
			text-align: center;
		}
		.days li {
			padding: 4px !important;
			height: 56px !important;
		}
		.weekdays li{
			padding: 8px !important;
		}
		.calFooter ul li{
			font-size: 12px !important;
		}
	}
}