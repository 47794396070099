// Overides Bootstrap variables
$font-family-sans-serif: 'PT Sans', sans-serif;
// Custome Variables
$primaryColor: #1d6838;
$secondaryColor: #183e23;
$tertiaryColor: #a4b63c;
$quaternaryColor: #e9e00d;
$placeholderColor: #ccc;
$btnbg:#73a541;
$white: #fff;
$black: #000;
$gray: #666;
$gray1: #bbb;
$darkGray: #404040;
$lightGray: #ebebeb;
$borderColor:#ccc;
$bodyFontSize: 18px;
$bodyLineHeight: 1.4;
$bodyFont: 'PT Sans', sans-serif;
$headingFont: 'PT Serif', serif;
$h1Size: 36px;
$h2Size: 30px;
$h3Size: 24px;
$h4Size: 20px;

@mixin resetUl {
    list-style: none;
    margin: 0;
    padding: 0;
}

$baseFontSize: $bodyFontSize;
@function r($pixels, $context: $baseFontSize) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }
    @if (unitless($context)) {
        $context: $context * 1px;
    }
    @return $pixels / $context * 1rem;
}